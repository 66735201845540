.product-section {
    margin: 15px 0px;
}

.mission-section {
    padding: 15px 0px;
}

.bg-image {
    position: absolute;
    width: 100%;
}