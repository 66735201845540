.navbar-select-box {
    background-color: transparent;
    border: none !important;
    outline: none !important;
    margin: 0 15px;
}


.navbar-placeholder {
    color: #fff;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}


@media only screen and (max-width: 480px){
    .navbar-placeholder {
        color: black;
        text-transform: capitalize;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
    }
}

.navbar-select-box > .MuiOutlinedInput-notchedOutline {
    border: none;
    outline: none;
}