@media screen and (min-width: 800px) {
    .youtube-video-card {
        width: 500px;
    }

    .youtube-video-card  iframe {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .youtube-video-card {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .youtube-video-card  iframe {
        width: 100%;
        height: 50vw;
    }
}