.service-section {
    padding: 10px 30px;
}

.service-card-image {
    width: auto !important;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.services-sub-section {
    padding-bottom: 15px;
}

.services-sub-section-title {
    padding-top: 30px;
    padding-left: 30px;
    text-align: left !important;
}